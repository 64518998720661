.LandingMain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 5.5rem;
  background: #f5fcff;
  font-style: normal;

  @include respond(phone) {
    grid-template-columns: 1fr;
  }

  &__Col1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 13.5rem;

    @include respond(phone) {
      padding: 0;
      order: 2;
      align-items: center;
      text-align: center;
      padding: 3.2rem;
    }
    &--Para1 {
      font-weight: bold;
      font-size: 4rem;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 1.6rem;

      @include respond(phone) {
        font-size: 2.8rem;
      }
    }
    &--Para2 {
      font-weight: 450;
      font-size: 2.4rem;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 4rem;
      @include respond(phone) {
        font-size: 1.8rem;
      }
    }
    &--Button {
      background: #009ae0;
      border-radius: 4px;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 150%;
      text-align: center;
      color: #ffffff;
      padding: 1rem 5.6rem;
      cursor: pointer;
      outline: none;
      border: none;
      @include respond(phone) {
        font-size: 1.8rem;
        padding: 11px 0;
        width: 100%;
      }
    }
  }

  &__Col2 {
    &--Img {
      width: 100%;

      @include respond(phone) {
        width: 100%;
        order: 1;
        display: none;
      }
    }
    &--ImgMobile {
      width: 100%;
      display: none;

      @include respond(phone) {
        width: 100%;
        order: 1;
        display: flex;
      }
    }
  }
}
