.GivenLeads {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;

  &__Back {
    width: 80%;
    @include respond(phone) {
      display: none;
    }
  }

  &__Back button {
    padding: 1rem 1.8rem;
    border: 1px solid #407bff;
    box-sizing: border-box;
    border-radius: 4px;
    background: #ffffff;
    color: #407bff;
  }

  &__Back button:hover {
    cursor: pointer;
  }

  &__Head {
    font-size: 32px;
    font-weight: bold;
    line-height: 60px;
    text-align: center;
    color: #0c2450;
    margin-bottom: 5rem;
    @include respond(phone) {
      display: none;
    }
  }

  &__Modal {
    width: 100%;
    margin-bottom: 6rem;
  }
}

.SuccessMsgLeads {
  margin-top: 13rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include respond(phone) {
    margin-top: 3rem;
  }

  &__Para {
    width: 40%;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 3.6rem;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 5rem;
    text-align: center;
  }
  &__Img {
    width: 50%;
  }
}
