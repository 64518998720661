.LoanProcessFlow {
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;

  @include respond(phone) {
    padding: 60px 32px;
  }

  &__Header {
    font-weight: bold;
    font-size: 3.6rem;
    line-height: 150%;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);

    @include respond(phone) {
      font-size: 2.4rem;
      line-height: 3.6rem;
    }
  }

  &__Flow {
    width: 60%;
    margin-top: 8rem;

    @include respond(phone) {
      margin-top: 4rem;
      width: 100%;
    }

    &--Content {
      display: flex;
      flex-direction: row;
      align-items: end;
      width: 100%;

      &__Number {
        width: 7%;
        margin-top: 1.8rem;

        @include respond(phone) {
          margin-top: 0.8rem;
          width: 10%;
        }
      }

      &__BodyDiv {
        margin-left: 3.2rem;
        width: 90%;
        margin-bottom: 3.5rem;
        background: #fcfcfc;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;

        @include respond(phone) {
        }
      }

      &__Div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #ffffff;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 3.2rem;
        cursor: pointer;

        @include respond(phone) {
          padding: 1.6rem;
        }

        &--ArrowDown {
          @include respond(phone) {
            width: 5%;
          }
        }

        &--Para {
          font-weight: 450;
          font-size: 2.2rem;
          line-height: 140%;
          color: rgba(0, 0, 0, 0.87);
          width: 80%;

          @include respond(phone) {
            font-size: 1.5rem;
          }
        }
      }
    }

    &--ContentDetails {
      padding: 2.4rem;

      @include respond(phone) {
        padding: 1.6rem;
      }
      &__Para {
        font-weight: 450;
        font-size: 2rem;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.6);
        @include respond(phone) {
          font-size: 1.5rem;
        }
      }
      &__Button {
        margin-top: 24px;
        font-weight: 500;
        font-size: 2rem;
        line-height: 150%;
        text-align: center;
        color: #ffffff;
        background: #009ae0;
        border-radius: 4px;
        padding: 1rem 4.2rem;
        cursor: pointer;
        outline: none;
        border: none;

        @include respond(phone) {
          font-size: 1.5rem;
          padding: 0.8rem 1.2rem;
        }
      }
    }
  }

  &__Button {
    margin-top: 8rem;
    font-weight: 500;
    font-size: 2rem;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
    background: #009ae0;
    border-radius: 4px;
    padding: 1.8rem 12.6rem;
    cursor: pointer;
    outline: none;
    border: none;

    @include respond(phone) {
      margin-top: 4rem;
      font-size: 1.8rem;
      padding: 1.2rem 0;
      width: 100%;
    }
  }

  &__AlreadyRegistered {
    font-weight: 450;
    font-size: 2.4rem;
    line-height: 150%;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 4rem;

    @include respond(phone) {
      font-size: 1.6rem;
      width: 70%;
    }
  }
}
