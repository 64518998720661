.ProgramDetails {
  font-style: normal;
  padding: 120px 13.5rem;

  @include respond(phone) {
    padding: 60px 32px;
  }

  &__Header {
    font-weight: bold;
    font-size: 3.6rem;
    line-height: 150%;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    padding-bottom: 24px;

    @include respond(phone) {
      font-size: 2.4rem;
    }
  }
  &__Para {
    font-weight: 450;
    font-size: 2.4rem;
    line-height: 150%;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);

    @include respond(phone) {
      font-size: 1.6rem;
    }
  }
  &__Para span {
    color: #00cc71;
  }
}
