.DocumentsRequired {
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fffcfc;

  @include respond(phone) {
    padding: 60px 32px 4rem;
  }

  &__Header {
    font-weight: bold;
    font-size: 3.6rem;
    line-height: 4.6rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 2.4rem;

    @include respond(phone) {
      font-size: 2.4rem;
      line-height: 3.6rem;
    }
  }

  &__Para {
    font-weight: 450;
    font-size: 2.4rem;
    line-height: 3.6rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 6rem;

    @include respond(phone) {
      font-size: 1.6rem;
      line-height: 150%;
    }
  }

  &__Details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5rem;
    width: 90%;
    margin-bottom: 3.2rem;

    @include respond(phone) {
      grid-template-columns: 1fr;
      grid-gap: 1.6rem;
      margin-bottom: 1.6rem;
      width: 100%;
    }

    &--Content {
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: end;
      padding: 1.6rem;

      &__Para {
        font-style: normal;
        font-weight: 450;
        font-size: 2rem;
        line-height: 2.5rem;
        color: rgba(0, 0, 0, 0.87);
      }

      &__Para2 {
        font-weight: 450;
        font-size: 1.6rem;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.6);
        margin-top: 0.8rem;
      }

      &__ParaDiv {
        width: 80%;
      }
    }
  }

  &__Details2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5rem;
    width: 60%;

    @include respond(phone) {
      grid-template-columns: 1fr;
      grid-gap: 1.6rem;
      width: 100%;
    }

    &--Content {
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: end;
      padding: 1.6rem;

      &__Para {
        font-style: normal;
        font-weight: 450;
        font-size: 2rem;
        line-height: 2.5rem;
        color: rgba(0, 0, 0, 0.87);
      }

      &__Para2 {
        font-weight: 450;
        font-size: 1.6rem;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.6);
        margin-top: 0.8rem;
      }

      &__ParaDiv {
        width: 80%;
      }
    }
  }
}
