.StudentDetailsSuccess {
    display: flex;
    align-items: center;
    flex-direction: column;

    &-mobileLogo{
        display: none;

        @include respond(phone) {
            width: 90%;
            display: flex;
            justify-content: flex-end;
        }
    }

    &--Heading {
        font-style: normal;
        font-weight: bold;
        font-size: 3.2rem;
        line-height: 6rem;
        text-align: center;
        color: rgba(0, 0, 0, 0.87);

        @include respond(phone) {
            font-size: 2.4rem;
            line-height: 3.6rem;
            margin-top: 3.2rem;
          }

    }

    &--Body {
        width: 100%;
        margin-top: 4rem;
        background: #FFFFFF;
        border: 1px solid #F5F5F5;
        box-sizing: border-box;
        box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);
        border-radius: 10px 10px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond(phone) {
            margin-top: 6rem;
            background: #FFFFFF;
            border: 1px solid #F5F5F5;
            box-sizing: border-box;
            width: 90%;
            box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);
            border-radius: 20px 20px 0 0;
        }

        &-Row1 {
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 150%;
            margin-top: 3.2rem;
            text-align: center;
            color: rgba(0, 0, 0, 0.87);

            @include respond(phone) {
                font-weight: 450;
                font-size: 1.8rem;
                margin-top: 2.4rem;
            }

        }

        &-Row2 {
            font-style: normal;
            font-weight: 450;
            font-size: 1.6rem;
            line-height: 150%;
            display: flex;
            align-items: center;
            text-align: center;
            color: rgba(0, 0, 0, 0.6);
            display: flex;
            justify-content: center;
            width: 70%;
            margin: auto;
            margin-top: 1.6rem;

            @include respond(phone) {
                font-weight: 450;
                font-size: 1.6rem;
            }
        }

        &-Row3 {
            font-style: normal;
            font-weight: 450;
            font-size: 1.6rem;
            line-height: 150%;
            display: flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.6);
            margin: auto;
            margin-top: 8rem;

            @include respond(phone){
                font-weight: 450;
                font-size: 1.6rem;
            }
        }

        &-Footer {
            display: flex;
            justify-content: center;
            background: #FFFFFF;
            border: 1px solid #009AE0;
            border-radius: 4px;
            width: 60%;
            margin: auto;
            margin-top: 1.6rem;
            margin-bottom: 10rem;

            @include respond(phone){
                width: 80%;
            }

            &-Btn{
                font-style: normal;
                font-weight: 450;
                font-size: 1.8rem;
                line-height: 2rem;
                display: flex;
                justify-content: center;
                text-align: center;
                padding: 1.4rem 0;
                color: #009AE0;
                background: #fff;
                outline: none;
                border: none;
                width: 99%;
                cursor: pointer;

                @include respond(phone){
                    padding: 1.1rem 0;
                }
            }
        }
    }

    &--Body img {
        margin-top: 6rem;

        @include respond(phone) {
            margin-top: 4rem;
            width: 80px;
            height: 80px;
        }

    }
   
}