.LeadsModal {
  display: flex;
  justify-content: center;
  &__modal {
    display: flex;
    // position: fixed;
    // z-index: 1000;
    // left: 0;
    // top: 0;
    width: 45%;
    flex-direction: row;
    // justify-content: center;
    align-items: center;
    // overflow: auto;
    // height: 100%;
    // overflow: auto;
    // background-color: rgb(0, 0, 0);
    // background-color: rgba(0, 0, 0, 0.4);

    &--next:hover {
      cursor: pointer;
    }

    @include respond(phone) {
      display: flex;
      flex-direction: column;
      z-index: 1000;
      position: fixed;
      z-index: 1000;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: #ffffff;
    }
  }

  &__modalContainer {
    background: #ffffff;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1),
      0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: auto;
    // border: 0.1rem solid #888;
    width: 100%;

    // display: flex;
    // flex-direction: column;
    // align-items: center;

    @include respond(phone) {
      background-color: #ffffff;
      margin: auto;
      border: none;
      margin-top: 3.2rem;
      margin-bottom: 2.2rem;
      box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.12),
        0px 4px 12px rgba(0, 0, 0, 0.1);
      width: 90%;
      border-radius: none;
      overflow-y: auto;
    }
  }

  &__modalBody {
    // margin-top: 4rem;
    // width: 93%;
    padding: 0rem 10rem;
    text-align: justify;
    @include respond(phone) {
      margin-top: 3rem;
    }
    // display: -webkit-flexbox;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;

    // border-bottom: 1px solid #eee;

    &--modalTopSection {
      background: #f7fbfe;
      border: 0.5px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 12px 24px;
      margin: 1.6rem 10rem 0;

      @include respond(phone) {
        align-items: flex-start;
        padding: 12px;
        margin: 1.6rem;
      }

      &-Text {
        margin-left: 16px;
        display: flex;
        flex-direction: column;

        @include respond(phone) {
          margin-left: 12px;
        }

        &-Heading {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: rgba(0, 0, 0, 0.87);
        }

        &-SubText {
          font-style: normal;
          font-weight: 450;
          font-size: 14px;
          line-height: 150%;
          margin-top: 2px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    &--modalTopSection img {
      @include respond(phone) {
        height: 32px;
        width: 32px;
      }
    }

    &--coApplicantContentHeading {
      margin-top: 0.6rem;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.87);

      @include respond(phone) {
        font-size: 14px;
      }
    }

    &--coApplicantContentText {
      font-style: normal;
      font-weight: 450;
      font-size: 14px;
      line-height: 150%;
      margin-top: 4px;
      color: rgba(0, 0, 0, 0.6);
    }

    &--coApplicantRadioGroup {
      margin-top: 1.2rem;

      &-radio {
        display: flex;
        align-items: center;
        margin: 12px 0;
      }

      &-radioBtn {
        width: 20px;
        height: 20px;
      }

      &-radio label {
        margin-left: 16px;
      }
    }

    &-StudentIndex {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      margin-top: 48px;
      letter-spacing: 0.1em;
      color: rgba(0, 0, 0, 0.6);

      @include respond(phone) {
        margin-top: 24px;
      }
    }

    &--RadioButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1.5rem;

      &__Para {
        font-style: normal;
        font-weight: 450;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #2d2d45;
        margin-left: 1.5rem;
      }

      &__Btn {
        width: 2rem;
        height: 2rem;
      }
    }

    @include respond(phone) {
      margin-top: 1rem;
      // width: 90%;
      padding: 1.6rem 3rem 0rem;
    }

    &--nameHead,
    &--instituteHead,
    &--MobileHead,
    &--EmailHead,
    &--cityHead,
    &--stateHead {
      // flex: 1;
      // -webkit-box-flex: 1;
      // -webkit-flex: 1;
      // display: flex;
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: rgba(0, 0, 0, 0.87);
      width: 100%;
      padding-bottom: 8px;

      @include respond(phone) {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #2d2d45;
      }
    }

    &--instituteHead {
      width: 100%;
      display: -webkit-flexbox;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &--instituteHead label {
      flex: 1;
    }

    &--name,
    &--state,
    &--institute {
      // flex: 1;
      // -webkit-box-flex: 1;
      // -webkit-flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: 0.1rem solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 0.4rem;
      margin-bottom: 2.4rem;
      padding: 14px 16px;
    }

    &--city {
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: 0.1rem solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 0.4rem;
      margin-bottom: 2.4rem;
      padding: 1rem;
    }

    // &--city img {
    //   position: relative;
    //   right: 1rem;
    //   top: 0.2rem;
    // }

    &--MobileNumber {
      // display: -webkit-flexbox;
      // display: flex;
      // flex: 1;
      // -webkit-box-flex: 1;
      // -webkit-flex: 1;
      display: -webkit-flexbox;
      display: flex;
      // padding: 1rem 0;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: 0.1rem solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 0.4rem;
      margin-bottom: 2.4rem;
    }

    &--state select,
    &--city select {
      appearance: none;
    }

    &--name input,
    &--state select,
    &--institute input,
    &--city select {
      width: 100%;
      // padding: 1.2rem 0;
      border: none;
      // margin-left: 1rem;
    }

    &--MobileNumber input {
      width: 100%;
      border: none;
      // padding: 1.2rem 0;
      padding-left: 1rem;
      padding-top: 14px;
      padding-bottom: 14px;
      border-left: 0.1rem solid #e5e5e5;
    }

    &--nameError,
    &--PhoneError,
    &--cityError,
    &--stateError,
    &--instituteError {
      flex: 1;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      margin-top: -2rem;
      color: red;
      font-size: 1.4rem;
      z-index: 100;
      margin-bottom: 0.8rem;
      width: 100%;
    }

    &--IN {
      padding: 14px 1.6rem;
    }
  }

  &__modalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.6rem 2.4rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 0.1rem solid #eee;

    @include respond(phone) {
      display: none;
    }

    &--Para {
      margin: 0;
    }
  }

  &__mobileHeader {
    display: none;
    @include respond(phone) {
      display: flex;
    }
  }

  &__mobileModal {
    display: none;

    @include respond(phone) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    &--Row1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      margin-top: 1rem;
    }

    &--Row2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 90%;
      margin-top: 4rem;

      font-size: 2.4rem;
      line-height: 3.6rem;
      font-weight: bold;
      color: #2d2d45;
    }
  }

  &__AddLeads {
    // flex: 1;
    // -webkit-box-flex: 1;
    // -webkit-flex: 1;
    display: flex;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px dashed #e5e5e5;
    padding-top: 1.6rem;

    &--Para {
      font-style: normal;
      // font-weight: 450;
      // font-size: 14px;
      // line-height: 21px;
      font-size: 1.4rem;
      line-height: 2.1rem;
      color: #7c7c82;

      @include respond(phone) {
        font-size: 1.4rem;
      }
    }

    &--Btn {
      background: #ffffff;
      border: 1px solid #bdbdbd;
      box-sizing: border-box;
      border-radius: 20px;
      padding: 0.8rem 2.4rem;
      font-size: 1.2rem;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);

      @include respond(phone) {
        font-size: 1.2rem;
        padding: 0.8rem 2.4rem;
        // padding: 0.5rem 1.4rem 0.3rem;
      }
    }
    &--Btn:hover {
      cursor: pointer;
    }
  }

  &__modalFooter {
    // width: 93%;
    padding: 1.6rem 10rem;
    flex: 1;
    margin-bottom: 4rem;

    @include respond(phone) {
      margin-bottom: 2rem;
      // padding: 0;
      padding: 1.6rem 24px;
      margin-top: 4rem;
      // width: 90%;
    }

    &--SaveBtn {
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.4rem;

      color: #ffffff;
      padding: 1.4rem 0rem;
      border: none;

      background: #009ae0;
      border-radius: 4px;
    }

    &--SaveBtn:hover {
      cursor: pointer;
    }
  }

  &--Close:hover {
    cursor: pointer;
  }

  &--SaveBtn:focus {
    outline: none;
  }
}

input:focus,
select:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

select {
  // margin: 40px;
  color: rgba(0, 0, 0, 0.6);
  background: #fff;
  // text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  // appearance: none;
}

input {
  color: rgba(0, 0, 0, 0.6);
}

.removeButton {
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #eb5757;
}
