.FeesCalculator {
  background-image: url("../../images/calculator.svg");
  background-size: cover;
  font-style: normal;

  @include respond(phone) {
    background-image: url("../../images/calculatorMobile.svg");
  }

  &__Working {
    padding: 12rem 0 8rem;

    @include respond(phone) {
      padding: 60px 32px;
    }

    &--Header {
      font-weight: bold;
      font-size: 3.6rem;
      line-height: 4.6rem;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 2.4rem;

      @include respond(phone) {
        font-size: 2.4rem;
        margin-bottom: 1.6rem;
      }
    }
    &--Para {
      padding: 0 13.5rem;
      font-weight: 450;
      font-size: 2.4rem;
      line-height: 3.6rem;
      text-align: center;
      color: #7c7c82;

      @include respond(phone) {
        font-size: 1.8rem;
        padding: 0;
      }
    }
  }

  &__Calculator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8rem 0 0rem;
    width: 100%;

    @include respond(phone) {
      padding: 20px 32px 60px 32px;
    }

    &--Header {
      font-weight: bold;
      font-size: 3.6rem;
      line-height: 4.6rem;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 2.4rem;

      @include respond(phone) {
        font-size: 2.4rem;
        margin-bottom: 1.6rem;
      }
    }
    &--Para {
      padding: 0 13.5rem;
      font-weight: 450;
      font-size: 2.4rem;
      line-height: 3.6rem;
      text-align: center;
      color: #7c7c82;

      @include respond(phone) {
        font-size: 1.8rem;
        padding: 0;
      }
    }
  }
}

.Calculator {
  margin-top: 6rem;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  padding: 6rem;
  width: 70%;

  @include respond(phone) {
    padding: 2.4rem;
    width: 100%;
  }

  &__Row1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include respond(phone) {
      flex-direction: column;
    }

    &--Col {
      width: 30%;
      @include respond(phone) {
        width: 100%;
      }

      &__Para {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 0.8rem;
      }

      &__InputDiv {
        background: #ffffff;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &--Input {
          border: none;
          padding: 1.2rem;
          width: 100%;
          border-left: 1px solid #e5e5e5;
        }

        &--Img {
          padding: 1rem 1.2rem;
        }
        @include respond(phone) {
          margin-bottom: 1.6rem;
        }
      }
      &__Input {
        background: #ffffff;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 1.2rem;
        width: 100%;
        @include respond(phone) {
          margin-bottom: 1.6rem;
        }
      }
      &__Select {
        background: #ffffff;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 4px;
        background: #ffffff;
        padding: 1.2rem;
        width: 100%;
        @include respond(phone) {
          margin-bottom: 1.6rem;
        }
      }
    }
  }

  &__Row2 {
    border-bottom: 2px dashed #e5e5e5;
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 4.1rem;

    @include respond(phone) {
      flex-direction: column;
      padding-bottom: 2.4rem;
    }

    &--Content1,
    &--Content2,
    &--Content3 {
      width: 30%;
      box-shadow: 0px 1px 2px rgba(156, 156, 156, 0.12);
      border-radius: 6px;
      padding: 1.6rem;

      @include respond(phone) {
        width: 100%;
        margin-bottom: 1.6rem;
      }
    }
    &--Content1 {
      background: #f1a951;
    }
    &--Content2 {
      background: #44b5e9;
    }
    &--Content3 {
      background: #1dbec8;
    }

    &--Details {
      width: 70%;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.3rem;
      color: #ffffff;
    }
    &--Number {
      margin-top: 1.2rem;
      font-weight: 900;
      font-size: 2.4rem;
      line-height: 3rem;
      color: #ffffff;
    }
  }

  &__Row3 {
    padding: 3rem 0 4rem;
    font-weight: 450;
    font-size: 2.4rem;
    line-height: 150%;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);

    @include respond(phone) {
      padding: 2.4rem;
      font-size: 1.6rem;
    }
  }

  &__Row4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &--Rate {
      width: 15%;
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 150%;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);

      @include respond(phone) {
        font-size: 1.2rem;
      }
    }
    &--Value {
      width: 20.5%;
      font-style: normal;
      font-weight: 450;
      font-size: 2.8rem;
      line-height: 150%;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      @include respond(phone) {
        font-size: 1.6rem;
      }
    }
    &--Arrow {
      width: 3%;
      @include respond(phone) {
        visibility: hidden;
        width: 4%;
      }
    }
  }

  &__Row5 {
    margin-top: 3.3rem;
    position: relative;
    &--Blue {
      background: #cff4fa;
      height: 1rem;
    }

    &--CircleDiv {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 0;
      margin-top: -2rem;

      @include respond(phone) {
        margin-top: -2.5rem;
      }

      &__CRate {
        width: 15%;
        visibility: hidden;
        align-content: center;
      }
      &__Circle {
        width: 20.5%;
        align-content: center;
        display: flex;
        justify-content: center;
      }
      &__CArrow {
        width: 3%;
        visibility: hidden;
      }
    }
  }

  &__Row6 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 3.1rem;

    &--Value6 {
      width: 20.5%;
      font-weight: 450;
      font-size: 2rem;
      line-height: 150%;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      @include respond(phone) {
        font-size: 1.2rem;
      }
    }
  }
}

input:focus,
select:focus {
  outline: none;
}
