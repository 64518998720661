.EMIProgramSuccess {
  margin-top: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;

  @include respond(phone) {
    margin-top: 2.4rem;
  }

  &__Header {
    font-weight: bold;
    font-size: 3.2rem;
    line-height: 187%;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    @include respond(phone) {
      font-size: 2.4rem;
    }
  }

  &__Container {
    margin-top: 4rem;
    width: 50%;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    box-sizing: border-box;
    box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);
    border-radius: 10px 10px 0px 0px;
    padding: 6rem 0rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include respond(phone) {
      margin-top: 2.4rem;
      width: 100%;
      background: #ffffff;
      border: 1px solid #f5f5f5;
      box-sizing: border-box;
      box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);
      border-radius: 20px;
      padding-top: 4rem;
    }

    &--Para1 {
      @include respond(phone) {
        width: 25%;
      }
    }

    &--Para2 {
      padding: 0 9rem;
      margin-top: 3.2rem;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 150%;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 1.6rem;
      @include respond(phone) {
        padding: 0 2.4rem;
        font-size: 1.8rem;
      }
    }

    &--Para3 {
      padding: 0 9rem;
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 150%;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 4rem;
      @include respond(phone) {
        padding: 0 2.4rem;
        font-size: 1.6rem;
      }
    }

    &--Para4 {
      padding: 0 9rem;
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 1.6rem;
      @include respond(phone) {
        padding: 0 2.4rem;
        font-size: 1.6rem;
      }
    }

    &--Button {
      width: 65%;
      background: #ffffff;
      border: 1px solid #009ae0;
      border-radius: 4px;
      font-weight: 450;
      font-size: 18px;
      line-height: 111%;
      text-align: center;
      color: #009ae0;
      padding: 1.4rem 0;
      margin-bottom: 8rem;
      cursor: pointer;
      outline: none;
      border: none;
      @include respond(phone) {
        width: 85%;
        font-size: 1.8rem;
      }
    }

    &--Footer {
      background: rgba(0, 154, 224, 0.04);
      border: 1px solid #f5f5f5;
      box-sizing: border-box;
      border-radius: 10px 10px 0px 0px;
      padding: 2.4rem 9.5rem;

      @include respond(phone) {
        padding: 2.4rem;
      }

      &__Content {
        margin-bottom: 1.6rem;
        display: flex;
        flex-direction: row;
        align-items: end;

        &--Para {
          margin-left: 1rem;
          text-align: left;
          font-weight: 450;
          font-size: 1.4rem;
          line-height: 150%;
          color: rgba(0, 0, 0, 0.6);
          @include respond(phone) {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  &__MobileHeader {
    width: 100%;
    display: none;
    @include respond(phone) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &--Next {
      visibility: hidden;
    }
  }
}

.PaddingBorder {
  @include respond(phone) {
    padding: 3.2rem 3.1rem;
  }
}
