.Home{
    display: flex;
    flex-direction: column;

    &-Footer {

        @include respond(phone)  {
            position: fixed;
            display: flex;
            justify-content: center;
            z-index: 1;
            bottom: 0;
            width: 100%;
            background: #FFFFFF;
            box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.12);
            padding: 12px 0;
        }

        &-btn {
            @include respond(phone) {
                background: #009AE0;
                border-radius: 4px;
                width: 90%;
                display: flex;
                justify-content: center;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                padding: 12px 0;
                text-align: center;
                color: #FFFFFF;
            } 
        }
        
    }
}