.ProgramBenefits {
  font-style: normal;
  background-image: url("../../images/benefits.png");
  background-size: contain;
  padding: 120px 0;
  background-color: #fcfcfc;

  @include respond(phone) {
    padding: 60px 0;
    background-image: url("../../images/benefitsMobile.svg");
  }

  &__Header {
    font-weight: bold;
    font-size: 3.6rem;
    line-height: 150%;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);

    @include respond(phone) {
      font-size: 2.4rem;
    }
  }

  &__Body {
    padding: 8rem 13.5rem 0;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    grid-gap: 3.8rem;

    @include respond(phone) {
      grid-template-columns: 2fr 2fr;
      padding: 4.2rem 3.2rem 0;
    }

    &--Content1,
    &--Content2,
    &--Content3,
    &--Content4 {
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 2.4rem;
      height: 24rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      @include respond(phone) {
        height: 17rem;
      }
    }

    &--Content1 {
      border-bottom: 8px solid #fabe2c;
    }
    &--Content2 {
      border-bottom: 8px solid #4d4d8c;
    }
    &--Content3 {
      border-bottom: 8px solid #00cc71;
    }
    &--Content4 {
      border-bottom: 8px solid #099cb8;
    }

    &--Para {
      font-weight: 450;
      font-size: 2.6rem;
      line-height: 150%;
      font-feature-settings: "liga" off;
      color: #18191f;

      @include respond(phone) {
        font-size: 1.8rem;
      }
    }

    &--Img {
      width: 35%;
    }
  }
}
