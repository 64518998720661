.JoinEMIProgram {
  @include respond(phone) {
    padding: 2.6rem 3rem;
  }

  &__Body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6.5rem;
    font-style: normal;

    @include respond(phone) {
      margin-top: 0rem;
    }
  }

  &__MobileHeader {
    display: none;

    @include respond(phone) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  &__Header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 4rem 0;

    &--Back {
      font-weight: 500;
      font-size: 1.6rem;
      height: 4.4rem;
      text-align: center;
      color: #009ae0;
      border: 1px solid #009ae0;
      box-sizing: border-box;
      border-radius: 6px;
      background: #ffffff;
      padding: 0rem 2.8rem;

      @include respond(phone) {
        display: none;
      }
    }
    &--Head {
      font-weight: bold;
      font-size: 32px;
      line-height: 187%;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);

      @include respond(phone) {
        font-size: 2.4rem;
        line-height: 150%;
      }
    }
    &--Dummy {
      color: #ffffff;
      @include respond(phone) {
        display: none;
      }
    }
  }

  &__Container {
    background: #ffffff;
    border: 1px solid #f5f5f5;
    box-sizing: border-box;
    box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);
    border-radius: 10px;
    padding: 6rem 9rem;
    width: 45%;

    @include respond(phone) {
      width: 100%;
      padding: 3rem 2.4rem;
      border: 1px solid #f5f5f5;
      box-sizing: border-box;
      box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);
      border-radius: 20px;
    }

    &--Form {
      margin-top: 4.6rem;
      &__Label {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 0.8rem;
      }

      &__SubLabel {
        font-weight: 450;
        font-size: 1.4rem;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.6);
        margin-top: -1rem;
        margin-bottom: 0.5rem;
      }

      &__FlexRow {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2.4rem;

        @include respond(phone) {
          flex-direction: column;
        }

        &--Col {
          width: 50%;

          @include respond(phone) {
            width: 100%;
          }
        }

        &--InputDiv {
          width: 90%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background: #ffffff;
          border: 1px solid #e5e5e5;
          box-sizing: border-box;
          border-radius: 4px;

          @include respond(phone) {
            width: 100%;
          }

          &__Input {
            border: none;
            width: 100%;
          }

          &--IN {
            padding: 1.4rem 1.6rem;
            border-right: 1px solid #e5e5e5;
          }

          &--PER {
            padding: 1.4rem 1.6rem;
            border-left: 1px solid #e5e5e5;
          }
        }
      }

      &__Input {
        width: 100%;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 1.4rem 1.6rem;
        margin-bottom: 2.4rem;
      }

      &__Select {
        width: 45%;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 1.4rem 1.6rem;

        @include respond(phone) {
          width: 100%;
        }
      }

      &__InputDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 2.4rem;

        &--Input {
          border: none;
          padding: 1.4rem 1.6rem;

          width: 100%;
        }
        &--IN {
          padding: 1.4rem 1.6rem;
          border-right: 1px solid #e5e5e5;
        }

        &--PER {
          padding: 1.4rem 1.6rem;
          border-left: 1px solid #e5e5e5;
        }

        // &__FlexRow {
        //   &--Input {
        //     border: none;
        //     padding-left: 2.6rem;
        //   }
        // }
      }

      &__SubHeading {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 2.4rem;
        // word-break: break-all;
      }

      &__RadioButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 2.5rem;

        &--Label {
          font-weight: 450;
          font-size: 1.6rem;
          line-height: 2rem;
          display: flex;
          align-items: center;
          color: rgba(0, 0, 0, 0.87);
          margin-left: 1.6rem;
        }

        &--Radio {
        }
      }

      &__Mode {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 70%;
        margin-top: 0.8rem;

        @include respond(phone) {
          width: 90%;
        }

        &--Col {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }

      &__Footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 1.6rem;
        border-top: 2px dashed #e5e5e5;
        margin: 2.2rem 0;

        &--Para {
          font-style: normal;
          font-weight: 450;
          font-size: 1.4rem;
          line-height: 2.1rem;
          color: #7c7c82;
          width: 70%;
        }

        &--Button {
          background: #009ae0;
          border: 1px solid #bdbdbd;
          box-sizing: border-box;
          border-radius: 20px;
          font-weight: 450;
          font-size: 1.2rem;
          line-height: 2rem;
          text-align: center;
          color: rgba(0, 0, 0, 0.87);
          padding: 0.6rem 2rem;
          cursor: pointer;
          outline: none;
          border: none;
        }
      }
    }

    &--Button {
      width: 100%;
      background: #009ae0;
      border-radius: 4px;
      font-weight: 450;
      font-size: 1.8rem;
      line-height: 111%;
      text-align: center;
      color: #ffffff;
      padding: 1.4rem 0;
      margin-top: 2rem;
      cursor: pointer;
      outline: none;
      border: none;
    }
  }
}

.ProgressBar {
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  align-items: end;

  @include respond(phone) {
    margin-top: 0;
  }

  &__Content {
    &--Dash {
      border: 1px solid #bdbdbd;
      height: 0.2rem;
      width: 3.2rem;
      margin-top: 1.4rem;
    }
    &--Step {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &__Para {
        margin-top: 0.8rem;
        font-style: normal;
        font-weight: 450;
        font-size: 1.4rem;
        line-height: 143%;
        color: #2d2d45;
      }
    }
  }
}

.removeButton {
  color: crimson;
  font-weight: 600;
  font-size: 1.2rem;
  margin-left: 0.6rem;
}

.MarginTop {
  margin-top: 3.3rem;
}

.MarginMobileTop {
  @include respond(phone) {
    margin-top: 2rem;
  }
}
