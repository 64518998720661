.ContactUs {
  padding: 60px 32px;
  background: #f7fafc;

  &__Header {
    font-weight: bold;
    font-size: 3.6rem;
    line-height: 4.6rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    padding-bottom: 2.4rem;
  }

  &__Para {
    font-weight: 450;
    font-size: 1.8rem;
    line-height: 150%;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
  }
}

.darkColor {
  color: #333333;
  font-weight: 600;
}
